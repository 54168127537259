// INFO: 논문 게시판 리스트

<script>
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'
import { getLabPaper } from '@/api/platform/community' // 논문 조회
import { deletePaperTable } from '@/api/admin/ntis/ntisDelete' // 논문 삭제
import AddBtn from '@/components/ui/AddBtn.vue' // 논문 등록

import LoadingVue from '@/components/ui/Loading.vue'
// INFO: 검색 기능
import Dropdown from '@/components/ui/Dropdown.vue'

export default {
  name: 'adminBoardPaperList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    LoadingVue,
    // INFO: 검색 기능
    Dropdown
  },
  data() {
    const labNo = Number(this.$route.params.labNo)

    return {
      currentPath: this.$route.matched[0].path, // 현재 접속 경로
      currentPage: '', // 현재 페이지 번호
      paperGrid: '', // 프로젝트 그리드
      labNo, // 연구실 번호

      // ********** 그리드 세팅 **********
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbar: ['Search'], // 툴바에 검색 옵션
      // formatoptions: { type: 'dateTime', format: 'y/M/d' }, // 컬럼 날짜 포맷. 시간까지 보이게 하려면 format: 'M/d/y hh:mm a'
      commands: [{ buttonOption: { content: '삭제', cssClass: 'e-danger' } }],

      // ********** 논문 데이터 **********
      paperData: [],
      // INFO: 검색 기능
      persistedData: [],
      searchConditions: [
        { value: 'all', text: '전체' },
        { value: 'labNo', text: '연구실번호' },
        { value: 'labName', text: '연구실이름' },
        { value: 'paperResultTitle', text: '논문명 ' },
        { value: 'paperAuthor', text: '저자명' },
        { value: 'paperIssnNumber', text: 'ISSN 번호' },
        { value: 'paperJournalName', text: '학술지명' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, CommandColumn]
  },

  watch: {
    async $route() {
      await this.loadPaperData()
    }
  },

  methods: {
    // ********** 논문 데이터 가져오기 **********
    async loadPaperData() {
      const user = this.$store.state.userStore.info

      const data = user.groupName === 'admin' ? await getLabPaper() : await getLabPaper({ labNo: user.labNo })

      // 연구소 번호가 0이 아닐 때 연구소 번호에 맞는 논문만 보여주기
      const data2 = this.labNo !== 0 ? await getLabPaper({ labNo: this.labNo }) : ''

      if (this.labNo !== 0) {
        this.paperData = data2.items
      } else {
        this.paperData = data.items
      }
    },

    // ********** 한 행 클릭 시 보기로 이동 **********
    paperRowSelected(e) {
      // 그리드 현재 페이지
      this.currentPage = this.paperGrid.pagerModule.pageSettings.currentPage

      // 한 행 클릭한 타켓이 버튼일 경우 리턴
      if (e.target.matches('button')) return

      if (this.labNo !== 0) {
        this.$router.push(`/admin/board/paper/w/${e.data.paperId}/${this.currentPage}/${e.data.labNo}`)
      } else {
        this.$router.push(`/admin/board/paper/w/${e.data.paperId}/${this.currentPage}/0`)
      }
    },

    // ********** 삭제 버튼 **********
    commandClick(e) {
      const target = e.target
      // 타겟이 삭제 버튼일 경우 삭제 동작 함수 호출
      if (target.matches('button.e-danger')) return this.delPaper(e)
    },

    // ********** 삭제 동작 **********
    async delPaper(e) {
      if (!confirm('선택한 논문을 정말로 삭제하시겠습니까?')) return

      const boardNo = e.rowData.paperId
      const result = deletePaperTable(boardNo, e.rowData)
      if (result.code) return alert(result.message)
      alert('삭제되었습니다.')
      this.paperData = this.paperData.filter((item) => item.paperId !== boardNo)
    },

    // ********** 등록 버튼 **********
    goPaperCreate() {
      this.$router.push(`${this.currentPath}/w`)
    },

    // INFO: 검색 기능
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      if (this.searchBy === 'all') return
      this.paperData = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    },
    checkSearchState() {
      const { labNo, board, page } = this.$store.state.searchStateStore

      if (board !== 'paper') {
        this.$store.dispatch('searchStateStore/initailize')
        return false
      }

      if (this.labNo) return false

      if (!labNo) return false

      this.labNo = labNo
      this.$router.replace(`/admin/board/paper/${labNo}`)
    }
  },
  async mounted() {
    this.isLoading = true

    // 논문 그리드
    this.paperGrid = this.$refs.grid.ej2Instances

    // 파라미터 프로젝트 그리드 페이지 번호
    const page = Number(this.$route.params.page)

    this.checkSearchState()

    // 논문 전체 데이터 가져오기
    await this.loadPaperData()

    // 페이지 번호가 있을 때(프로젝트 보기 -> 목록으로 돌아온 경우) 받아온 페이지 번호로 SET
    if (page) {
      this.paperGrid.pagerModule.pageSettings.currentPage = page
    } else {
      this.paperGrid.pagerModule.pageSettings.currentPage = 1
    }

    // INFO: 검색 기능
    this.setSearchOptions()
    const getList = async () => {
      this.paperData = this.paperData.map((item) => {
        return {
          paperId: item.paperId,
          labNo: item.labNo,
          labName: item.labName,
          paperResultTitle: item.paperResultTitle,
          paperAuthor: item.paperAuthor,
          paperIssnNumber: item.paperIssnNumber,
          paperJournalName: item.paperJournalName
        }
      })

      // INFO: 검색 기능
      this.persistedData = this.paperData
    }
    getList()
    this.isLoading = false
  }
}
</script>

<template>
  <div>
    <!-- 등록 버튼 -->
    <AddBtn @click="goPaperCreate()"></AddBtn>

    <!-- 검색기능 -->
    <Dropdown
      class="select-wrapper"
      :options="searchConditions"
      :disabled="false"
      name="zipcode"
      ref="selectOption"
      @selected="selectDropdownHandler"
    ></Dropdown>

    <!-- 논문게시판 그리드 -->
    <div class="">
      <ejs-grid
        ref="grid"
        :dataSource="paperData"
        :allowPaging="true"
        :pageSettings="pageSettings"
        :allowSorting="true"
        :toolbar="toolbar"
        :rowSelected="paperRowSelected"
        :commandClick="commandClick"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column field="paperId" headerText="번호" width="80" textAlign="left"></e-column>
          <e-column field="labNo" headerText="연구실번호" width="100" textAlign="left"></e-column>
          <e-column field="labName" headerText="연구실이름" width="120" textAlign="left"></e-column>
          <e-column field="paperResultTitle" headerText="논문명" width="400" textAlign="left"></e-column>
          <e-column field="paperAuthor" headerText="저자명" width="120" textAlign="left"></e-column>
          <e-column field="paperIssnNumber" headerText="ISSN 번호" width="120" textAlign="left"></e-column>
          <e-column field="paperJournalName" headerText="학술지명" width="180" textAlign="left"></e-column>
          <e-column field="manage" headerText="관리" width="150" textAlign="center" :commands="commands" />
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</template>

<style scoped></style>
